<template>
  <div>
    <v-btn
      :disabled="disabled"
      :color="color"
      :small="small"
      :loading="loading"
      @click.stop="$emit('click')"
    >
      <v-icon
        :class="classIcon"
        :small="small"
      >{{icon}}
      </v-icon>
      {{title}}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    classIcon: {
      type: String,
      default: 'mr-2'
    },
    disabled: Boolean,
    color: String,
    small: Boolean,
    icon: String,
    title: String,
    loading: Boolean
  }
}
</script>

<style scoped>

</style>
