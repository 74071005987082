<template>
  <div>
    <v-card>
      <v-card-title>Загрузить в базу</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="6">
            <uploader
              :options="optionsRio"
              class="uploader-example"
            >
              <uploader-unsupport></uploader-unsupport>
              <uploader-drop>
                <p>Загрузка базы Рио</p>
                <uploader-btn :attrs="attrs.rio">Выбрать файл</uploader-btn>
              </uploader-drop>
              <uploader-list></uploader-list>
            </uploader>
            <uploader
              :options="optionsLider"
              class="uploader-example"
            >
              <uploader-unsupport></uploader-unsupport>
              <uploader-drop>
                <p>Загрузка базы Лидер</p>
                <uploader-btn :attrs="attrs.lider">Выбрать файл</uploader-btn>
              </uploader-drop>
              <uploader-list></uploader-list>
            </uploader>
          </v-col>
          <v-col cols="6"
                 class="d-flex justify-space-around">
            <BaseBtn
              style="padding: 5px; margin: 10px auto 0;"
              title="Запустить парсер Базы161"
              @click="handleWebParser('base-161')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import * as Config from '@/config'
import BaseBtn from '@/components/views/admin/common/BaseButtonBar/BaseBtn'
import Vue from 'vue'

export default {
  name: 'Parser',
  components: { BaseBtn },
  data () {
    return {
      attrs: {
        lider: {
          accept: 'application/xml'
        },
        rio: {
          accept: 'application/msword'
        }
      }
    }
  },
  computed: {
    optionsRio () {
      return {
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: Config.baseUrl + '/parser/file/donrio',
        testChunks: false,
        chunkSize: 1024 * 1024,
        headers: {
          'Authorization': this.$store.getters['auth/getJwt']
        }
      }
    },
    optionsLider () {
      return {
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: Config.baseUrl + '/parser/file/lider',
        testChunks: false,
        chunkSize: 1024 * 1024,
        headers: {
          'Authorization': this.$store.getters['auth/getJwt']
        }
      }
    }
  },
  methods: {
    async handleWebParser (parser) {
      let response = await Vue.axios.post('/parser/web/' + parser)
      if (response.data.success) {
        await this.$store.dispatch('systemMessages/success', 'Парсер запущен')
      } else if (response.data.error) {
        await this.$store.dispatch('systemMessages/error', 'Ошибка при запуске')
      }
    }
  }
}
</script>

<style>
.uploader-example {
  padding: 5px;
  margin: 10px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
